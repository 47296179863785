document.addEventListener("DOMContentLoaded", function () {
  const prevBtn = document.querySelector("#prev-btn");
  const nextBtn = document.querySelector("#next-btn");

  if (!prevBtn || !nextBtn) return;
  
  function getActiveSection() {
    return document.querySelector(".tab-pane.show.active .interview-cards");
  }

  function updateButtonState(container) {
    if (!container) return;

    // vérifie les limites de scroll
    const maxScrollLeft = container.scrollWidth - container.clientWidth;

    const isAtStart = container.scrollLeft <= 0;
    const isAtEnd = container.scrollLeft >= maxScrollLeft;

    isAtStart ? prevBtn.classList.add('disabled') : prevBtn.classList.remove('disabled');
    isAtEnd ? nextBtn.classList.add('disabled') : nextBtn.classList.remove('disabled');
  }

  function scrollContainer(direction) {
    const container = getActiveSection();
    if (container) {
      const scrollAmount = 280 * direction;
      container.scrollBy({ left: scrollAmount, behavior: "smooth" });

      // attendre un peu pour mettre à jour les boutons après le scroll
      setTimeout(() => updateButtonState(container), 280);
    }
  }

  nextBtn.addEventListener("click", () => scrollContainer(1));
  prevBtn.addEventListener("click", () => scrollContainer(-1));

  // vérifie l'état des boutons au chargement et au scroll
  updateButtonState(getActiveSection());
  document.addEventListener("scroll", () => updateButtonState(getActiveSection()), true);
});