document.addEventListener('DOMContentLoaded', () => {
  // Submit form on change
  document.querySelectorAll('[data-submitonchange="true"]').forEach(element => {
    element.addEventListener('change', () => {
      this.form.submit();
    });
  });

  // Print on click
  document.querySelectorAll('[data-printonclick="true"]').forEach(element => {
    element.addEventListener('click', () => {
      window.print();
    });
  });
  // Print on click
  document.querySelectorAll('[data-openaxeptiocookies="true"]').forEach(element => {
    element.addEventListener('click', (e) => {
      e.preventDefault();
      openAxeptioCookies();
    });
  });
});
