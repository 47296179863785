document.addEventListener("DOMContentLoaded", () => {
  const step1 = $('#enable-mfa-step-1');
  const step2 = $('#enable-mfa-step-2');

  if (step1 && step2) {
    const nextButton = step1.find(".enable-mfa-step-next");
    const backButton = step2.find(".enable-mfa-step-back");

    if (nextButton) {
      nextButton.click(function() {
        step1.hide();
        step2.show();
        $('#enable_mfa_otp').focus();
        return false;
      });
    }

    if (backButton) {
      backButton.click(function() {
        step1.show();
        step2.hide();
        return false;
      });
    }
  }
})
