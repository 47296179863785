// import "../stylesheets/application_redesign";
import Rails from "@rails/ujs";
Rails.start();

require("jquery-ui");

import "bootstrap";
import "../javascript/lib/index";
import "widget.min";
import "jquery.vmap";
import "jquery.vmap.france";
import "../javascript/components/app_redesign";
import "../javascript/components/francemap_redesign";
import "../javascript/components/read_more";
import "../javascript/components/already_liked_projects";
import "../javascript/components/toast";
import "../javascript/components/sidebar_show_more";
import "../javascript/components/testimonials";
import "../javascript/components/toggle_password_visibility";

// File Upload
import "../javascript/legacy/file_upload";

import "../javascript/components/confetti";
import "../javascript/components/info_banner";
import "../javascript/legacy/modal";
import "../javascript/legacy/pages/project/show_page";
import "../javascript/pages/accounts/index";
import "../javascript/pages/projects/index"; // includes projects/countdown
import "../javascript/pages/subscriptions/index"; // includes projects/countdown
import "../javascript/pages/investor_app/index";

import "../javascript/components/shared/clipboard";

import "../javascript/components/copySponsorInviteToClipboard";

import "../javascript/components/otp_attempt_input";

// Make notie available everywhere
import notie from "notie";
window.notie = notie;
